body, html {
    font-size: 0.9rem;
    background: #f2f3f7
  }
  body, .form-control {
    font-size: 0.9rem
  }
  body,
  html,
  #root,
  .App {
    min-height: 100vh
  }